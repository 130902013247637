<template>
	<div style="height: 100%;">
		<main-table tool>
			<div slot="table" class="df f1 fdc">
				<div class="df fww">
					<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
						<div class="df jcsb mb5">
							<div class="fs16 fw6 c3">{{status.count || 0}}</div>
						</div>
						<div class="fs14 c6">{{status.name}}</div>
					</div>
					<div class="mb10">
						<el-button type="primary" size="mini" @click="show_fillter = !show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
					</div>
				</div>
				<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
					<el-form-item>
						<el-input v-model="params.keyword" placeholder="申请流水/离职理由" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model="params.employees" placeholder="请选择申请人" filterable  multiple collapse-tags>
							<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
							<el-option label="入职时间" value="hired"></el-option>
							<el-option label="离职时间" value="separated"></el-option>
							<el-option label="申请时间" value="created"></el-option>
						</el-select>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getDepartures({ ...params, page: 1})">查询</el-button>
					</el-form-item>
				</el-form>
				<div class="f1 pr">
					<el-table ref="employee_table" class="bill scroll-wrapper pa" height="100%" :data="tables" :size="theme.size">
						<el-table-column label="申请人" prop="employee.name" min-width="90"></el-table-column>
						<el-table-column label="申请流水" min-width="160">
							<el-link slot-scope="scope" type="primary" @click="$refs['departure'].open(scope.row);" :disabled="!$utils.update($api.URI_EMPLOYEES_DEPARTURES)">{{scope.row.no}}</el-link>
						</el-table-column>
						<el-table-column label="工作时长" min-width="60">
							<template slot-scope="scope">{{scope.row | diff_at}} 天</template>
						</el-table-column>
						<el-table-column label="离职理由" prop="reason" min-width="160"></el-table-column>
						<el-table-column label="入职时间" prop="hired_at" min-width="150"></el-table-column>
						<el-table-column label="离职时间" prop="separated_at" min-width="150"></el-table-column>
						<el-table-column label="申请时间" prop="created_at" min-width="150"></el-table-column>
						<el-table-column label="状态" min-width="60">
							<template slot-scope="scope">
								<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
									<span>{{statuses[scope.row.status] || '未知'}}</span>
								</el-tooltip>
								<span v-else>{{statuses[scope.row.status] || '未知'}}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" min-width="60">
							<template slot-scope="scope">
								<el-link type="primary" @click="delDeparture(scope.row)">删除</el-link>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getDepartures({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getDepartures({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<departure-show ref="departure" :status="statuses" @refresh="getDepartures(params)"></departure-show>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTable from '@/components/main-table';
	import departureShow from '@/pages/hrm/departures/show';

	export default {
		components: {
			mainTable,
			departureShow
		},
		computed: {
			...mapState(['theme'])
		},
		filters: {
			diff_at (t) {
				if (!t) return '无';
				return moment(t.separated_at).diff(t.hired_at, 'day');
			},
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getDepartures(this.params);
			},
			async getDepartures (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_DEPARTURES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.statuses = result.status;
				this.employees = result.employees;
				if (result.counts) {
					var status_tabs = {};
					const status = params.status || [];
					for (var s in this.statuses) {
						status_tabs[s] = {
							name: this.statuses[s],
							count: result.counts[s] || 0,
							checked: status.indexOf(s) >= 0 ? true : false
						};
					}
					this.status_tabs = status_tabs;
				}
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			delDeparture ({ id, employee, separated_at }) {
				if (this.$moment().isAfter(separated_at)) return this.$message.error('已超过离职时间不能操作删除！');
				this.$confirm(employee.name + ' 的离职申请 ', '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleDepartures({action: 'delete', id});
				});
			},
			async handleDepartures (params) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_DEPARTURES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getDepartures(this.params);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				tables: [],
				shops: [],
				statuses: [],
				employees: [],
				status_tabs: [],
				show_fillter: false,
				employee: {},
				params: {
					status: [],
					perPage: 10,
				}
			}
		},
		created () {
			this.getDepartures(this.params, true);
		}
	};
</script>